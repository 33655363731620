<template>
    <div class="report-montly-bookings">
        <md-card>
            <md-card-header>
                <h4 style="margin:0">Monthly Bookings</h4>
            </md-card-header>
            <md-card-content>
                <div class="chart-container" style="height:400px"></div>
            </md-card-content>
            <md-card-content>
                <div class="common-list">
                    <md-toolbar class="md-transparent">
                        <div class="md-toolbar-container">
                            <span style="flex:1"></span>
                            <date-range-picker :showUpcoming="false" v-model="dateRange"></date-range-picker>
                        </div>
                    </md-toolbar>
                    <md-table>
                        <md-table-header>
                            <md-table-row>
                                <md-table-head>Date</md-table-head>
                                <md-table-head>Bookings</md-table-head>
                                <md-table-head>Qty</md-table-head>
                                <md-table-head>Total</md-table-head>
                            </md-table-row>
                        </md-table-header>
                        <md-table-body>
                            <template v-for="(r,ix) in records">
                                <md-table-row :key="ix">
                                    <md-table-cell>{{r._id | date('MMMM YYYY')}}</md-table-cell>
                                    <md-table-cell>{{r.count}}</md-table-cell>
                                    <md-table-cell>{{r.quantity}}</md-table-cell>
                                    <md-table-cell>{{r.total | currency}}</md-table-cell>
                                </md-table-row>
                            </template>
                            <md-table-row>
                                <md-table-cell>Total</md-table-cell>
                                <md-table-cell>{{countTotal}}</md-table-cell>
                                <md-table-cell>{{qtyTotal}}</md-table-cell>
                                <md-table-cell>{{subTotal | currency}}</md-table-cell>
                            </md-table-row>
                        </md-table-body>
                    </md-table>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import echarts from "echarts";
import DateRangePicker from "@/components/DateRangePicker";
import moment from "moment-timezone";
export default {
    components: {
        DateRangePicker
    },
    data() {
        return {
            dateRange: [
                moment()
                    .startOf("day")
                    .subtract(12, "months")
                    .startOf("month")
                    .toDate(),
                moment()
                    .startOf("day")
                    .subtract(1, "months")
                    .endOf("month")
                    .toDate()
            ],
            records: [],
            chart: null
        };
    },

    computed: {
        from() {
            if (!this.dateRange || this.dateRange == "All") {
                return undefined;
            }
            return this.dateRange[0] ? moment(this.dateRange[0]).toDate() : null;
        },
        to() {
            if (!this.dateRange || this.dateRange == "All") {
                return undefined;
            }
            return this.dateRange[1] ? moment(this.dateRange[1]).toDate() : null;
        },
        xData() {
            return this.records.map(r => r._id);
        },
        yData() {
            return this.records.map(r => r.total);
        },
        countTotal() {
            return this.records.reduce((acc, r) => acc + r.count, 0);
        },
        qtyTotal() {
            return this.records.reduce((acc, r) => acc + r.quantity, 0);
        },
        subTotal() {
            return this.records.reduce((acc, r) => acc + r.total, 0);
        }
    },
    watch: {
        async dateRange() {
            await this.loadSales();
            this.initChart();
        }
    },
    methods: {
        async loadSales() {
            let result = await this.$store.dispatch("crud/get", {
                api: "reports/monthly-bookings",
                params: {
                    from: this.from,
                    to: this.to
                }
            });

            let from = moment(this.from).clone();
            let to = moment(this.to).clone();
            for (let m = from; m.isBefore(to); m.add(1, "month")) {
                if (!result.find(r => r._id == m.format("YYYY-MM"))) {
                    result.push({ _id: m.format("YYYY-MM"), count: 0, quantity: 0, total: 0 });
                }
            }
            result.sort((a, b) => {
                return a._id > b._id ? 1 : -1;
            });

            this.records = result;
        },
        initChart() {
            let container = this.$el.querySelector(".chart-container");
            let options = {
                xAxis: {
                    data: this.xData,

                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    z: 10
                },
                yAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: "#999"
                        }
                    }
                },
                series: [
                    {
                        type: "bar",
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: "#67bf5c" }, { offset: 0.5, color: "#73d667" }, { offset: 1, color: "#67b95d" }])
                            }
                        },
                        label: {
                            // show:true
                        },
                        data: this.yData
                    }
                ]
            };

            this.chart = this.chart || echarts.init(container);
            this.chart.setOption(options);
        }
    },
    async mounted() {
        await this.loadSales();
        this.initChart();
    }
};
</script>



